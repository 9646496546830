<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2023年10月30日 16:42:34
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <v-app>
    <page-header></page-header>
    <shopmall-404></shopmall-404>
    <page-footer></page-footer>
  </v-app>
</template>
