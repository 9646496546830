import request from "../request";
export function listCartDetail(data) {
  return request({
    url: "/cart/api/v1/cart/listCartDetail",
    method: "post",
    body: data
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    // }
  });
}

// 更新发送样品标记
export function updateSampleFlag(data) {
  return request({
    url: "/cart/api/v1/cart/updateSampleFlag",
    method: "post",
    body: data
  });
}

// 更新规格1
export function updateSpecs(data) {
  return request({
    url: "/cart/api/v1/cart/updateSpecs",
    method: "post",
    body: data
  });
}

// 更新数量
export function updateQty(data) {
  return request({
    url: "/cart/api/v1/cart/updateQty",
    method: "post",
    body: data
  });
}

// 更新印刷
export function updatePrint(data) {
  return request({
    url: "/cart/api/v1/cart/updatePrint",
    method: "post",
    body: data
  });
}

// 删除购物车
export function deleteCart(id) {
  return request({
    url: `/cart/api/v1/cart/delete/${id}`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 将cookie中的产品merge至登录用户的购物车
export function merge() {
  return request({
    url: `/cart/api/v1/cart/merge`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取购物车数量
export function countProduct() {
  return request({
    url: `/cart/api/v1/cart/countProduct`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 切换国家计算运费价格
export function listCalculatePriceDetail(data) {
  return request({
    url: `/cart/api/v1/cart/listCalculatePriceDetail`,
    method: "post",
    body: data
  });
}
