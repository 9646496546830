// composables/useJsencrypt.js

export default function useJsencrypt() {
  const publicKey =
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAlYBCEwhxMD2uFBDE64i6UypwmV9w0PZsNtTGwiZgajhcJqF4y0dIZ1ilARJsm1kcn+a2Llf0q2d6mUvzseucwNC0/4H3EoJDlqr4JpsRGWIJ3dHKZHG3BnLM9suX4J9xhqVLMXS3dfPWa1mxrx2Z0FhZ/yvfs2Cwcjhy7EVlFwIDAQAB";
  function encryptData(data) {
    if (!window.JSEncrypt) {
      console.error("JSEncrypt is not loaded!");
      return false;
    }
    const encryptor = new window.JSEncrypt(); // 创建 JSEncrypt 实例
    encryptor.setPublicKey(publicKey); // 设置公钥
    data =
      typeof data === "string"
        ? encodeURI(data)
        : encodeURI(JSON.stringify(data));
    return encryptor.encryptLong(data); // 加密数据
  }

  return {
    encryptData
  };
}
