export const useOrderStore = defineStore("order", {
  state() {
    return {
      orderPayObject: {},
      countryObject: {}, // 国家
      stateObject: {}, // 地区
    };
  },

  actions: {
    setOrderPayObject(object = {}) {
      this.orderPayObject = object;
    },
    setCountryObject(object = {}) {
      this.countryObject = object
    },
    setStateObject(object = {}) {
      this.stateObject = object
    },
  },
  persist: true,
});
