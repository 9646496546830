export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("page:finish", () => {
    const { customerService } = useRuntimeConfig().public;
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = customerService;
    s1.setAttribute("crossorigin", "*");
    s1.onload = () => {
      if (window?.Tawk_API) {
        const router = nuxtApp.$router;
        router.beforeEach((to, from, next) => {
          if (to.fullPath === "/checkout") {
            window.Tawk_API.hideWidget();
          } else {
            window.Tawk_API.showWidget();
          }
          next();
        });
      }
    };
    s0.parentNode.insertBefore(s1, s0);
  });
});
