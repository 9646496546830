import {
  auditCustomerListPage,
  deleteCustomerAddress,
  getDefultCustomerAddress,
  saveCustomerAddress,
  saveCustomerAddressWhenOrder,
  upDefultCustomerAddress,
  updateCustomerAddress,
  checkBlackCompanyAndAddress
} from "../services/api/address";
class AddressInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new AddressInteractor();

  async auditCustomerListPageApi(data) {
    //获取地址
    let res = await auditCustomerListPage(data);
    return res;
  }

  async deleteCustomerAddressApi(data) {
    //删除地址
    let res = await deleteCustomerAddress(data);
    return res;
  }

  async getDefultCustomerAddressApi(data) {
    //获取默认地址
    let res = await getDefultCustomerAddress(data);
    return res;
  }

  async saveCustomerAddressApi(data) {
    //新增客户地址
    let res = await saveCustomerAddress(data);
    return res;
  }

  async saveCustomerAddressWhenOrderApi(data) {
    //新增订单时批量新增客户地址
    let res = await saveCustomerAddressWhenOrder(data);
    return res;
  }

  async upDefultCustomerAddressApi(data) {
    //设置默认地址
    let res = await upDefultCustomerAddress(data);
    return res;
  }

  async updateCustomerAddressApi(data) {
    //更新客户地址
    let res = await updateCustomerAddress(data);
    return res;
  }

  async checkBlackCompanyAndAddressApi(data) {
    //更新客户地址
    let res = await checkBlackCompanyAndAddress(data);
    return res;
  }
}

export default AddressInteractor.getInstance();
