import request from "../request";
export function login(data) {
  return request({
    url: "/auth/authLogin/userLoginMall",
    method: "post",
    body: data,
    headers: {
      encode: true
    }
  });
}

export function emailLoginMall(param) {
  return request({
    url: `/auth/authLogin/emailLoginMall`,
    method: "post",
    body: {
      param
    }
  });
}

export function getUserInfo(data) {
  return request({
    url: "/auth/authLogin/checkTokenEncode",
    method: "post",
    body: data,
    headers: {
      decode: true
    }
  });
}

export function customerDetail(customerId) {
  return request({
    url: "/customer/customer/customerDetailEncode",
    method: "post",
    body: {
      customerId
    },
    headers: {
      decode: true
    }
  });
}

/**
 * 退出登录
 */

export function loginOut(customerId) {
  return request({
    url: "/auth/authLogin/loginOut",
    method: "post"
  });
}

export function getTokenRepeat(data) {
  return request({
    url: `/auth/authLogin/refreshToken?time=${Date.now()}`,
    method: "post",
    body: data
  });
}

export function checkEmail(loginName) {
  return request({
    url: `/system/mallLogin/checkEmail`,
    method: "post",
    body: {
      loginName
    }
  });
}
