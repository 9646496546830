export const useCartStore = defineStore("cart", {
  state() {
    return {
      cartList: [],
      cartCounts: 0,
      priceInfo: {},
    };
  },

  actions: {
    setCartList(cartList) {
      this.cartList = cartList;
    },

    setCartCounts(cartCounts) {
      this.cartCounts = cartCounts;
    },
  },
  persist: true,
});
