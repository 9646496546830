import request from "../request";
// 获取地址
export function auditCustomerListPage(data) {
  return request({
    url: `/customer/customerAddress/auditCustomerListPage`,
    method: "post",
    body: data
  });
}

// 删除地址
export function deleteCustomerAddress(data) {
  return request({
    url: `/customer/customerAddress/deleteCustomerAddress`,
    method: "post",
    body: data
  });
}

// 获取默认地址
export function getDefultCustomerAddress(data) {
  return request({
    url: `/customer/customerAddress/getDefultCustomerAddress`,
    method: "post",
    body: data
  });
}

// 新增客户地址
export function saveCustomerAddress(data) {
  return request({
    url: `/customer/customerAddress/saveCustomerAddress`,
    method: "post",
    body: data
  });
}

// 新增订单时批量新增客户地址
export function saveCustomerAddressWhenOrder(data) {
  return request({
    url: `/customer/customerAddress/saveCustomerAddressWhenOrder`,
    method: "post",
    body: data
  });
}

// 设置默认地址
export function upDefultCustomerAddress(data) {
  return request({
    url: `/customer/customerAddress/upDefultCustomerAddress`,
    method: "post",
    body: data
  });
}

// 更新客户地址
export function updateCustomerAddress(data) {
  return request({
    url: `/customer/customerAddress/updateCustomerAddress`,
    method: "post",
    body: data
  });
}
// 校验收货公司、地址黑名单

export function checkBlackCompanyAndAddress(data) {
  return request({
    url: `/customer/release/customerAddressBlack/checkBlackCompanyAndAddress`,
    method: "post",
    body: data
  });
}
