import {
  listCartDetail,
  updatePrint,
  updateQty,
  updateSpecs,
  updateSampleFlag,
  deleteCart,
  merge,
  countProduct,
  listCalculatePriceDetail
} from "../services/api/cart";
import { useUserStore } from "~/stores";
class CartInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new CartInteractor();

  //获取购物车列表
  async listCartDetailApi(data = {}) {
    let params = {};
    if (Object.keys(data).length) {
      params = data;
    } else {
      const { postCode, residential } = useUserStore().zipCodeInfo;
      params = {
        shipToAddress: {
          postCode,
          residential,
          country: "US"
        },
        sampleToAddress: {
          residential,
          postCode,
          country: "US"
        }
      };
    }
    let res = await listCartDetail(params);
    return res;
  }

  // 更新印刷
  async updatePrintApi({
    cartId,
    printingLocations,
    printingSurfaces,
    specsValue
  }) {
    let res = await updatePrint({
      cartId,
      printingLocations,
      printingSurfaces,
      specsValue
    });
    return res;
  }

  // 更新数量
  async updateQtyApi({ cartId, productSpecs, quantity }) {
    let res = await updateQty({ cartId, productSpecs, quantity });
    return res;
  }

  // 更新发送样品标记
  async updateSampleFlagApi({ cartId, sampleFlag }) {
    let res = await updateSampleFlag({ cartId, sampleFlag });
    return res;
  }

  // 更新规格1
  async updateSpecsApi({ cartId, specsType, specsValue }) {
    return updateSpecs({
      cartId,
      specsType,
      specsValue
    });
  }

  // 删除购物车
  async deleteCartApi(cartId) {
    let res = await deleteCart(cartId);
    return res;
  }

  // 删除购物车
  async mergeApi() {
    let res = await merge();
    return res;
  }

  // 获取购物车数量
  async countProductApi() {
    return await countProduct();
  }

  /**
   * 切换国家计算运费价格
   * @param {cartIds} 购物车Id
   * @param {shipCountry} 物流地址-国家
   * @param {sampleCountry} 样品地址-国家
   */
  listCalculatePriceDetailApi(params) {
    return listCalculatePriceDetail(params);
  }
}

export default CartInteractor.getInstance();
