import {
  activityInProgress,
  activityProductPage,
  activityInProgressId,
  orderInProgress,
  matchDiscount
} from "../services/api/promotions";

class PromotionsInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new PromotionsInteractor();

  //获取清仓活动
  async activityInProgressApi() {
    return await activityInProgress();
  }
  // 根据id获取活动详情
  async activityInProgressIdApi(id) {
    return await activityInProgressId(id);
  }

  // 查询进行中的清仓活动
  async activityProductPageApi(data) {
    const { activityId, current, size } = data;
    return await activityProductPage({ activityId, current, size });
  }

  // orderInProgress
  async orderInProgressApi() {
    return await orderInProgress();
  }

  // matchDiscount
  async matchDiscountApi(data) {
    const { discountCode, orderAmount, useCountLimit } = data;
    return await matchDiscount({ discountCode, orderAmount, useCountLimit });
  }
}

export default PromotionsInteractor.getInstance();
