import bootomIconRP from "~/assets/imgs/u436.png";
import bootomIconRG from "~/assets/imgs/rg-bottom.png";
import bootomIconSW from "~/assets/imgs/sw-bottom.png";
import lazyimgplaceholderRP from "~/assets/imgs/lazyimgplaceholder.webp";
import lazyimgplaceholder from "~/assets/imgs/lazyimgplaceholder.jpg";
import sageLogoRP from "~/assets/imgs/footer-sage.png";
import sageLogoRG from "~/assets/imgs/rgfooter-sage.png";
import sageLogoSW from "~/assets/imgs/rgfooter-sage.png";
import footerLogoRP from "~/assets/imgs/footer-logo.png";
import footerLogoRG from "~/assets/imgs/rgfooter-logo.png";
import footerLogoSW from "~/assets/imgs/swfooter-logo.png";
import clearancerpbg from "~/assets/imgs/clearance.svg";
import clearancergbg from "~/assets/imgs/clearanceRg.svg";
export default defineNuxtPlugin(nuxtApp => {
  const currentPlatform = import.meta.env.VITE_NODE_Mall;
  const multiPlatform = {};
  multiPlatform.mall = currentPlatform;
  if (currentPlatform === "RP") {
    multiPlatform.companyName = "Rivers Promo";
    multiPlatform.website = "www.riverspromo.com";
    multiPlatform.infoMailbox = "info@riverspromo.com";
    multiPlatform.bootomIcon = bootomIconRP;
    multiPlatform.lazyimgplaceholder = lazyimgplaceholderRP;
    multiPlatform.sageFooterLogo = sageLogoRP;
    multiPlatform.footerLogo = footerLogoRP;
    multiPlatform.clearancebg = clearancerpbg;
  } else if (currentPlatform === "RG") {
    multiPlatform.companyName = "Rivers Garment";
    multiPlatform.website = "www.riversgarment.com";
    multiPlatform.infoMailbox = "info@riversgarment.com";
    multiPlatform.bootomIcon = bootomIconRG;
    multiPlatform.lazyimgplaceholder = lazyimgplaceholder;
    multiPlatform.sageFooterLogo = sageLogoRG;
    multiPlatform.footerLogo = footerLogoRG;
    multiPlatform.clearancebg = clearancergbg;
  } else {
    multiPlatform.companyName = "Slight Worlds";
    multiPlatform.website = "www.slightworlds.com";
    multiPlatform.infoMailbox = "info@slightworlds.com";
    multiPlatform.bootomIcon = bootomIconSW;
    multiPlatform.lazyimgplaceholder = lazyimgplaceholder;
    multiPlatform.sageFooterLogo = sageLogoSW;
    multiPlatform.footerLogo = footerLogoSW;
    multiPlatform.clearancebg = clearancerpbg;
  }
  return {
    provide: {
      multiPlatform
    }
  };
});
