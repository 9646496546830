import request from "../request";
// 获取清仓活动信息
export function activityInProgress() {
  return request({
    url: `/product/api/v1/promotions/clearance/activityInProgress`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 通过活动id获取清仓活动详情
export function activityInProgressId(activityId) {
  return request({
    url: `/product/api/v1/promotions/clearance/activity/${activityId}`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取清仓商品信息
export function activityProductPage(data) {
  return request({
    url: "/product/api/v1/promotions/clearance/activityProductPage",
    method: "post",
    body: data
  });
}

// 查询进行中的订单折扣
export function orderInProgress() {
  return request({
    url: `/product/api/v1/promotions/discount/orderInProgress`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取折扣金额
export function matchDiscount(data) {
  return request({
    url: "/product/api/v1/promotions/discount/matchDiscount",
    method: "post",
    body: data
  });
}
