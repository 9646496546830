import { Confirm } from "~/plugins/swal";
export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("page:finish", () => {
    if (window && window.location.pathname !== "/") {
      if (typeof BroadcastChannel === "undefined") return;
      const BC = new BroadcastChannel("LOGIN");
      BC.onmessage = () => {
        Confirm({
          title:
            "<p>You have switched to another account.</p><p> Please refresh to continue.</p>",
          showCancelButton: false
        }).then(result => {
          if (result?.isConfirmed) {
            window.location.reload();
          }
        });
      };
    }
  });
});
