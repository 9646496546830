<template>
  <v-navigation-drawer
    v-model="drawer"
    :width="width"
    :location="location"
    temporary
    :disable-route-watcher="true"
  >
    <slot name="header"></slot>
    <div class="drawer-content">
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
const props = defineProps({
  width: {
    type: Number,
    default: 750,
  },
  location: {
    type: String,
    default: "right",
  },
});
const title = ref("");
const emit = defineEmits(["drawer-close"]);
const drawer = ref(false);

const btnColor = "rgba(0, 0, 0, 0.6)";

const show = () => {
  drawer.value = !drawer.value;
};

// 使用watch监听drawer
watch(
  () => drawer.value,
  (val) => {
    const htmlEl = document.querySelector("html");
    if (!val) {
      htmlEl.style.overflowY = "auto";
    } else {
      htmlEl.style.overflowY = "hidden";
    }
  }
);

defineExpose({
  show,
});
</script>

<style lang="scss" scoped>
.drawer-content {
  height: calc(100% - 40px);
  overflow-y: auto;
}
.top {
  height: 49px;
  line-height: 49px;
  border-bottom: 1px solid #ddd;
  position: relative;
  .v-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
.address-list {
  background: #f8f8f8;
}
.btns {
  height: 59px;
  border-top: 1px solid #ddd;
}
</style>
