import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const baseOptions = {
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "YES",
  confirmButtonColor: "#2196F3",
  denyButtonText: `Cancel`,
};

const mergeOptions = (options) => {
  if (typeof options === "string") {
    options = {
      title: options,
    };
  }
  return Object.assign({}, baseOptions, options);
};

export const Confirm = (options) => {
  return Swal.fire(mergeOptions(options));
};

// 通用Toast提示
export const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const Loading = (title = "Loading") => {
  Swal.fire({
    title,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    onOpen: () => {
      Swal.showLoading();
    },
  });
  return Swal;
};

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      Swal,
      Confirm,
      Message,
      Loading,
    },
  };
});

const Message = {
  success: (title = "Success") => {
    return Toast.fire({
      icon: "success",
      title,
    });
  },

  error: (title = "Error") => {
    return Toast.fire({
      icon: "error",
      title,
    });
  },

  warning: (title = "Warning") => {
    return Toast.fire({
      icon: "warning",
      title,
    });
  },
};
