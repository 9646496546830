export const useProductStore = defineStore("product", {
  state() {
    return {
      showFilter: false,
      accessories: "", // 点击的分类名称
      accessoriesId: "", //点击的分类id
      parentMenuName: "",
      product: {},
      imprintMethod: [], // 印刷方式字典
    };
  },

  actions: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    setAccessories({ id, nameEn }) {
      this.accessories = nameEn;
      this.accessoriesId = id;
    },

    setProduct(product) {
      this.product = {
        ...this.product,
        ...product,
      };
    },

    setParentMenuName(name) {
      this.parentMenuName = name;
    },

    setImprintMethod(array) {
      this.imprintMethod = array
    }
  },
  persist: true,
});
