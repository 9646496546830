<!--
  功能：功能描述
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2023年10月30日 16:42:34
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="d-flex flex-column align-center flex1 py-8">
    <div class="flex1 w-100 d-flex align-center flex-column justify-center">
      <img :src="four0four" alt="" />
      <p class="fs-14 fc-black-09 mb-10">Page Not Found</p>
      <v-btn @click="handleError" color="primary">Go Back Home</v-btn>
    </div>
  </div>
</template>

<script setup>
import four0four from "~/assets/imgs/404.svg";
import PageHeader from "~/components/page-header.vue";

const handleError = () => clearError({ redirect: "/" });
</script>

<style scoped></style>
