// 物流轨迹配置项
const logisticsConfig = {
  // 空运配置
  air: [
    {
      cn: "订单已创建",
      en: "/",
      show: false
    },
    {
      cn: "国内已装舱",
      en: "Arrived at international airport hub.",
      show: true
    },
    {
      cn: "国内已报关",
      en: "/",
      show: false
    },
    {
      cn: "国内已起航",
      en: "Departed from the international airport.",
      show: true
    },
    {
      cn: "国外已到港",
      en: "Arrived at destinational international airport.",
      show: true
    },
    {
      cn: "海外清关",
      en: "/",
      show: false
    },
    {
      cn: "海外已提货",
      en: "/",
      show: false
    },
    {
      cn: "海外已入仓",
      en: "/",
      show: false
    },
    {
      cn: "国外已发货",
      en: "Arranged to the local courier for delivery. ",
      show: true
    }
  ],
  // 海运配置
  sea: [
    {
      cn: "订单已创建",
      en: "/",
      show: false
    },
    {
      cn: "国内已装柜",
      en: "Arrived in the port warehouse.",
      show: true
    },
    {
      cn: "国内报关",
      en: "/",
      show: false
    },
    {
      cn: "国内已开船",
      en: "Departed from the port.",
      show: true
    },
    {
      cn: "海外清关",
      en: "/",
      show: false
    },
    {
      cn: "国外已提柜",
      en: "Arrived at destinational international airport.",
      show: true
    },
    {
      cn: "海外已入仓",
      en: "/",
      show: false
    },
    {
      cn: "国外已发货",
      en: "Local courie picked up from our warehouse for the local delivery. ",
      show: true
    }
  ]
};

// 深拷贝
function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

/**
 * 判断是否存在产前样品
 * @param {array} 物流进度集合
 * @returns Boolean 是否存在
 */
function isExistSample(array) {
  if (array?.length) {
    return array.some(item => item.sampleFlag);
  } else {
    return false;
  }
}

/**
 * 物流进度返回值排序
 * @param {array} 物流进度集合
 * @returns 物流进度集合-样品放在最后
 */
function logisticsSort(array) {
  if (array?.length) {
    const sampleArray = array.filter(item => item.sampleFlag);
    const noSampleArray = array.filter(item => !item.sampleFlag);

    return noSampleArray.concat(sampleArray);
  } else {
    return [];
  }
}

/**
 * 处理物流进度下拉项
 * @param {array} 物流进度集合
 * @returns 下拉字典项
 */
function logisticsItems(array) {
  const arraySort = logisticsSort(array);

  if (arraySort.length) {
    const arr = [];
    arraySort.forEach((item, i) =>
      arr.push({
        label: `Package #${i + 1}`,
        subTitle: item.sampleFlag ? "Physical Sample" : "",
        receivingAddress: item?.receivingAddress ?? "",
        receivingCompany: item?.receivingCompany ?? "",
        receivingPerson: item?.receivingPerson ?? ""
      })
    );

    return arr;
  } else {
    return [];
  }
}

/**
 * 处理物流进度数据-委托单尾程=>运单号=>委托单头程
 * @param {logisticsObj} 物流轨迹对象
 * @returns
 */
function handleLogistics(logisticsObj) {
  if (logisticsObj.shipOrderLogistics?.length) {
    // 委托单尾程节点
    let tailNode = logisticsObj.shipOrderLogistics[0].tailTrackNodesDtos;

    if (tailNode.length) {
      tailNode = tailNode.filter(
        item => item?.data?.hasCar || getTailNodesInfo("company", item)
      );
      tailNode = getTailNodesInfo(
        "node",
        tailNode?.[0],
        logisticsObj.shippingMethodType
      );
      // 委托单尾程
      if (tailNode.length) {
        return [deepCopy(tailNode), ""];
      }
    }
  }
  if (logisticsObj.shipNoLogistics?.length) {
    const node = getTailNodesInfo(
      "node",
      logisticsObj.shipNoLogistics[0],
      logisticsObj.shippingMethodType
    );

    if (node?.length) {
      return [deepCopy(node), ""];
    }
  }
  if (logisticsObj.shipOrderLogistics?.length) {
    // 委托单头程
    const headNode = logisticsObj.shipOrderLogistics[0].trackNodeDtos;
    if (headNode.length) {
      return [deepCopy(headNode), "head"];
    }
  } else {
    return [false, ""];
  }
}

/**
 * 过滤头程节点
 * @param {nodes} 头程节点
 * @param {method} 运输方式
 * @returns 头程节点
 */
function filterHeadNodes(nodes, method) {
  let [_nodes, config] = [[], []];

  if (!nodes?.length) return [];
  if (![1, 2].includes(method)) return false;
  if (method === 1) {
    config = logisticsConfig.air;
  } else if (method === 2) {
    config = logisticsConfig.sea;
  } else {
    config = [];
  }

  // 过滤时间节点,翻译nodeName
  for (let i = 0; i < nodes.length; i++) {
    for (let n = 0; n < config.length; n++) {
      if (nodes[i].nodeName === config[n].cn) {
        if (config[n].show) {
          _nodes.push({
            nodeTime: nodes[i].nodeTimeUS,
            nodeName: config[n].en
          });
          break;
        }
      }
    }
  }

  return _nodes;
}

/**
 * 获取尾程节点信息
 * @param {node} 节点名称
 * @param {item} 源数据
 * @param {shippingMethodType} 运输方式
 */
function getTailNodesInfo(node, item, shippingMethodType) {
  const _info = item?.data?.track_info;

  if (_info) {
    if (node === "company") {
      return _info?.tracking?.providers[0]?.provider?.name;
    } else if (node === "status") {
      return _info?.latest_status?.status;
    } else if (node === "cases") {
      return _info?.misc_info?.pieces;
    } else {
      let events = _info?.tracking?.providers[0]?.events || [];
      if (shippingMethodType === 3) {
        return events;
      } else {
        return events.filter(
          currentValue => currentValue.stage != "InfoReceived"
        );
      }
    }
  } else {
    return "";
  }
}

/**
 * 物流轨迹详情尾程集合
 * @param {data} 物流轨迹
 * @returns
 */
function getTailLogistics(data) {
  let tailLogistics = (
    data.shipOrderLogistics?.[0]?.tailTrackNodesDtos || []
  ).concat(data.shipNoLogistics || []);
  tailLogistics = tailLogistics.filter(
    item => item?.data?.hasCar || getTailNodesInfo("company", item)
  );

  return tailLogistics;
}

/**
 * 是否卡车派送
 * @param {data} 物流轨迹
 * @returns Boolean
 */
function getHasCar(data) {
  let hasCar = false;
  if (data?.shipOrderLogistics?.length) {
    let nodes = data.shipOrderLogistics?.[0]?.tailTrackNodesDtos;

    // nodes = nodes.filter(item => getTailNodesInfo('company', item))
    nodes = nodes.filter(
      item =>
        item?.data?.track_info?.tracking?.providers?.length ||
        item?.data?.hasCar
    );
    if (nodes?.length) {
      hasCar = nodes[0]?.data?.hasCar || false;
      return hasCar;
    }
  }
  if (data?.shipNoLogistics?.length) {
    return data.shipNoLogistics[0]?.data?.hasCar || false;
  }
  return hasCar;
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      Logistics: {
        logisticsConfig,
        isExistSample,
        logisticsSort,
        logisticsItems,
        handleLogistics,
        getTailNodesInfo,
        filterHeadNodes,
        getTailLogistics,
        getHasCar
      }
    }
  };
});
