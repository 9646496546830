import mitt from "mitt";
export const emitter = mitt();
export const Bus = {
  emit: emitter.emit,
  on: emitter.on,
  off: emitter.off,
};
export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      Bus,
    },
  };
});
