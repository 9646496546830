import {
  login,
  emailLoginMall,
  getUserInfo,
  customerDetail,
  loginOut,
  getTokenRepeat,
  checkEmail,
} from "../services/api/auth";

class AuthInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new AuthInteractor();

  //登录和获取登录信息
  async loginApi(loginName, passWord, verifyCode, browserType) {
    //登录
    let res = await login({
      loginName,
      passWord,
      verifyCode,
      browserType,
    });
    return res;
  }

  // 点击邮箱链接登录
  async emailLoginMallApi(param) {
    return await emailLoginMall(param);
  }

  async getUserInfoApi(originAccessToken, originRefreshToken) {
    //登录
    let res = await getUserInfo({
      originAccessToken,
      originRefreshToken,
    });
    return res;
  }

  async customerDetailApi(customerId) {
    //登录
    let res = await customerDetail(customerId);
    return res;
  }
  async loginOutApi() {
    //登录
    let res = await loginOut();
    return res;
  }

  async getTokenRepeatApi(data) {
    return await getTokenRepeat(data);
  }

  async checkEmailApi(email) {
    return await checkEmail(email);
  }
}

export default AuthInteractor.getInstance();
