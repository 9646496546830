<template>
  <v-card>
    <v-layout>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </v-layout>
  </v-card>
</template>

<style scoped>
.v-card {
  min-height: 100vh;
}
</style>
