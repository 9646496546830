import { default as account_45verifyPEgrfUAbNIMeta } from "/app/pages/account-verify.vue?macro=true";
import { default as forgot_45passworddr6WGYIGtdMeta } from "/app/pages/account/forgot-password.vue?macro=true";
import { default as reset_45password2xE76UMKhzMeta } from "/app/pages/account/reset-password.vue?macro=true";
import { default as signinBsDDApMR15Meta } from "/app/pages/account/signin.vue?macro=true";
import { default as signupUo01hwfY27Meta } from "/app/pages/account/signup.vue?macro=true";
import { default as _91id_936HUFlVShTqMeta } from "/app/pages/activity/clearance/[id].vue?macro=true";
import { default as _91id_93AGrWuIR2FhMeta } from "/app/pages/artwork-design/[id].vue?macro=true";
import { default as _91id_93csOQF5nkD7Meta } from "/app/pages/category/[id].vue?macro=true";
import { default as category_45listqzyphBGg0QMeta } from "/app/pages/category/category-list.vue?macro=true";
import { default as category_45searchs4lq0IycxgMeta } from "/app/pages/category/category-search.vue?macro=true";
import { default as checkoutLpmDViNIqPMeta } from "/app/pages/checkout.vue?macro=true";
import { default as errorcxo3GvC8JMMeta } from "/app/pages/error.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as browsing_45historyCb89jNKwsrMeta } from "/app/pages/my-account/browsing-history.vue?macro=true";
import { default as contactusygMa4EbSg8Meta } from "/app/pages/my-account/contactus.vue?macro=true";
import { default as order_45trackingfwdi849AesMeta } from "/app/pages/my-account/order-tracking.vue?macro=true";
import { default as _91id_93LOXjNOaFyWMeta } from "/app/pages/my-account/orders/[id].vue?macro=true";
import { default as indexg0spLlUUW1Meta } from "/app/pages/my-account/orders/index.vue?macro=true";
import { default as profileBrdsaCkokAMeta } from "/app/pages/my-account/profile.vue?macro=true";
import { default as trackingHHTXU8yiQWMeta } from "/app/pages/my-account/tracking.vue?macro=true";
import { default as my_45cart3vxh2l8Z30Meta } from "/app/pages/my-cart.vue?macro=true";
import { default as indexhtQlBzmjpXMeta } from "/app/pages/preview-file/index.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as _91id_93QIRuLOTNJ6Meta } from "/app/pages/product/[id].vue?macro=true";
import { default as register_45statuslfYvc0jz5wMeta } from "/app/pages/register-status.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as shoppingcartxQGFaRhZZLMeta } from "/app/pages/shoppingcart.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: account_45verifyPEgrfUAbNIMeta?.name ?? "account-verify",
    path: account_45verifyPEgrfUAbNIMeta?.path ?? "/account-verify",
    meta: account_45verifyPEgrfUAbNIMeta || {},
    alias: account_45verifyPEgrfUAbNIMeta?.alias || [],
    redirect: account_45verifyPEgrfUAbNIMeta?.redirect || undefined,
    component: () => import("/app/pages/account-verify.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passworddr6WGYIGtdMeta?.name ?? "account-forgot-password",
    path: forgot_45passworddr6WGYIGtdMeta?.path ?? "/account/forgot-password",
    meta: forgot_45passworddr6WGYIGtdMeta || {},
    alias: forgot_45passworddr6WGYIGtdMeta?.alias || [],
    redirect: forgot_45passworddr6WGYIGtdMeta?.redirect || undefined,
    component: () => import("/app/pages/account/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password2xE76UMKhzMeta?.name ?? "account-reset-password",
    path: reset_45password2xE76UMKhzMeta?.path ?? "/account/reset-password",
    meta: reset_45password2xE76UMKhzMeta || {},
    alias: reset_45password2xE76UMKhzMeta?.alias || [],
    redirect: reset_45password2xE76UMKhzMeta?.redirect || undefined,
    component: () => import("/app/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signinBsDDApMR15Meta?.name ?? "account-signin",
    path: signinBsDDApMR15Meta?.path ?? "/account/signin",
    meta: signinBsDDApMR15Meta || {},
    alias: signinBsDDApMR15Meta?.alias || [],
    redirect: signinBsDDApMR15Meta?.redirect || undefined,
    component: () => import("/app/pages/account/signin.vue").then(m => m.default || m)
  },
  {
    name: signupUo01hwfY27Meta?.name ?? "account-signup",
    path: signupUo01hwfY27Meta?.path ?? "/account/signup",
    meta: signupUo01hwfY27Meta || {},
    alias: signupUo01hwfY27Meta?.alias || [],
    redirect: signupUo01hwfY27Meta?.redirect || undefined,
    component: () => import("/app/pages/account/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_936HUFlVShTqMeta?.name ?? "activity-clearance-id",
    path: _91id_936HUFlVShTqMeta?.path ?? "/activity/clearance/:id()",
    meta: _91id_936HUFlVShTqMeta || {},
    alias: _91id_936HUFlVShTqMeta?.alias || [],
    redirect: _91id_936HUFlVShTqMeta?.redirect || undefined,
    component: () => import("/app/pages/activity/clearance/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93AGrWuIR2FhMeta?.name ?? "artwork-design-id",
    path: _91id_93AGrWuIR2FhMeta?.path ?? "/artwork-design/:id()",
    meta: _91id_93AGrWuIR2FhMeta || {},
    alias: _91id_93AGrWuIR2FhMeta?.alias || [],
    redirect: _91id_93AGrWuIR2FhMeta?.redirect || undefined,
    component: () => import("/app/pages/artwork-design/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93csOQF5nkD7Meta?.name ?? "category-id",
    path: _91id_93csOQF5nkD7Meta?.path ?? "/category/:id()",
    meta: _91id_93csOQF5nkD7Meta || {},
    alias: _91id_93csOQF5nkD7Meta?.alias || [],
    redirect: _91id_93csOQF5nkD7Meta?.redirect || undefined,
    component: () => import("/app/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: category_45listqzyphBGg0QMeta?.name ?? "category-category-list",
    path: category_45listqzyphBGg0QMeta?.path ?? "/category/category-list",
    meta: category_45listqzyphBGg0QMeta || {},
    alias: category_45listqzyphBGg0QMeta?.alias || [],
    redirect: category_45listqzyphBGg0QMeta?.redirect || undefined,
    component: () => import("/app/pages/category/category-list.vue").then(m => m.default || m)
  },
  {
    name: category_45searchs4lq0IycxgMeta?.name ?? "category-category-search",
    path: category_45searchs4lq0IycxgMeta?.path ?? "/category/category-search",
    meta: category_45searchs4lq0IycxgMeta || {},
    alias: category_45searchs4lq0IycxgMeta?.alias || [],
    redirect: category_45searchs4lq0IycxgMeta?.redirect || undefined,
    component: () => import("/app/pages/category/category-search.vue").then(m => m.default || m)
  },
  {
    name: checkoutLpmDViNIqPMeta?.name ?? "checkout",
    path: checkoutLpmDViNIqPMeta?.path ?? "/checkout",
    meta: checkoutLpmDViNIqPMeta || {},
    alias: checkoutLpmDViNIqPMeta?.alias || [],
    redirect: checkoutLpmDViNIqPMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: errorcxo3GvC8JMMeta?.name ?? "error",
    path: errorcxo3GvC8JMMeta?.path ?? "/error",
    meta: errorcxo3GvC8JMMeta || {},
    alias: errorcxo3GvC8JMMeta?.alias || [],
    redirect: errorcxo3GvC8JMMeta?.redirect || undefined,
    component: () => import("/app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: browsing_45historyCb89jNKwsrMeta?.name ?? "my-account-browsing-history",
    path: browsing_45historyCb89jNKwsrMeta?.path ?? "/my-account/browsing-history",
    meta: browsing_45historyCb89jNKwsrMeta || {},
    alias: browsing_45historyCb89jNKwsrMeta?.alias || [],
    redirect: browsing_45historyCb89jNKwsrMeta?.redirect || undefined,
    component: () => import("/app/pages/my-account/browsing-history.vue").then(m => m.default || m)
  },
  {
    name: contactusygMa4EbSg8Meta?.name ?? "my-account-contactus",
    path: contactusygMa4EbSg8Meta?.path ?? "/my-account/contactus",
    meta: contactusygMa4EbSg8Meta || {},
    alias: contactusygMa4EbSg8Meta?.alias || [],
    redirect: contactusygMa4EbSg8Meta?.redirect || undefined,
    component: () => import("/app/pages/my-account/contactus.vue").then(m => m.default || m)
  },
  {
    name: order_45trackingfwdi849AesMeta?.name ?? "my-account-order-tracking",
    path: order_45trackingfwdi849AesMeta?.path ?? "/my-account/order-tracking",
    meta: order_45trackingfwdi849AesMeta || {},
    alias: order_45trackingfwdi849AesMeta?.alias || [],
    redirect: order_45trackingfwdi849AesMeta?.redirect || undefined,
    component: () => import("/app/pages/my-account/order-tracking.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LOXjNOaFyWMeta?.name ?? "my-account-orders-id",
    path: _91id_93LOXjNOaFyWMeta?.path ?? "/my-account/orders/:id()",
    meta: _91id_93LOXjNOaFyWMeta || {},
    alias: _91id_93LOXjNOaFyWMeta?.alias || [],
    redirect: _91id_93LOXjNOaFyWMeta?.redirect || undefined,
    component: () => import("/app/pages/my-account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexg0spLlUUW1Meta?.name ?? "my-account-orders",
    path: indexg0spLlUUW1Meta?.path ?? "/my-account/orders",
    meta: indexg0spLlUUW1Meta || {},
    alias: indexg0spLlUUW1Meta?.alias || [],
    redirect: indexg0spLlUUW1Meta?.redirect || undefined,
    component: () => import("/app/pages/my-account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: profileBrdsaCkokAMeta?.name ?? "my-account-profile",
    path: profileBrdsaCkokAMeta?.path ?? "/my-account/profile",
    meta: profileBrdsaCkokAMeta || {},
    alias: profileBrdsaCkokAMeta?.alias || [],
    redirect: profileBrdsaCkokAMeta?.redirect || undefined,
    component: () => import("/app/pages/my-account/profile.vue").then(m => m.default || m)
  },
  {
    name: trackingHHTXU8yiQWMeta?.name ?? "my-account-tracking",
    path: trackingHHTXU8yiQWMeta?.path ?? "/my-account/tracking",
    meta: trackingHHTXU8yiQWMeta || {},
    alias: trackingHHTXU8yiQWMeta?.alias || [],
    redirect: trackingHHTXU8yiQWMeta?.redirect || undefined,
    component: () => import("/app/pages/my-account/tracking.vue").then(m => m.default || m)
  },
  {
    name: my_45cart3vxh2l8Z30Meta?.name ?? "my-cart",
    path: my_45cart3vxh2l8Z30Meta?.path ?? "/my-cart",
    meta: my_45cart3vxh2l8Z30Meta || {},
    alias: my_45cart3vxh2l8Z30Meta?.alias || [],
    redirect: my_45cart3vxh2l8Z30Meta?.redirect || undefined,
    component: () => import("/app/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: indexhtQlBzmjpXMeta?.name ?? "preview-file",
    path: indexhtQlBzmjpXMeta?.path ?? "/preview-file",
    meta: indexhtQlBzmjpXMeta || {},
    alias: indexhtQlBzmjpXMeta?.alias || [],
    redirect: indexhtQlBzmjpXMeta?.redirect || undefined,
    component: () => import("/app/pages/preview-file/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QIRuLOTNJ6Meta?.name ?? "product-id",
    path: _91id_93QIRuLOTNJ6Meta?.path ?? "/product/:id()",
    meta: _91id_93QIRuLOTNJ6Meta || {},
    alias: _91id_93QIRuLOTNJ6Meta?.alias || [],
    redirect: _91id_93QIRuLOTNJ6Meta?.redirect || undefined,
    component: () => import("/app/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: register_45statuslfYvc0jz5wMeta?.name ?? "register-status",
    path: register_45statuslfYvc0jz5wMeta?.path ?? "/register-status",
    meta: register_45statuslfYvc0jz5wMeta || {},
    alias: register_45statuslfYvc0jz5wMeta?.alias || [],
    redirect: register_45statuslfYvc0jz5wMeta?.redirect || undefined,
    component: () => import("/app/pages/register-status.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect || undefined,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: shoppingcartxQGFaRhZZLMeta?.name ?? "shoppingcart",
    path: shoppingcartxQGFaRhZZLMeta?.path ?? "/shoppingcart",
    meta: shoppingcartxQGFaRhZZLMeta || {},
    alias: shoppingcartxQGFaRhZZLMeta?.alias || [],
    redirect: shoppingcartxQGFaRhZZLMeta?.redirect || undefined,
    component: () => import("/app/pages/shoppingcart.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionszK6eZNsNEXMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionszK6eZNsNEXMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    alias: terms_45and_45conditionszK6eZNsNEXMeta?.alias || [],
    redirect: terms_45and_45conditionszK6eZNsNEXMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]